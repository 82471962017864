import { useEffect, useState } from 'react';
import { any, bool, string, object } from 'prop-types';
import dynamic from 'next/dynamic';
import Layout from 'components/layout';
import { COOKIES, getCookie } from 'utils/helpers';
import { getLandingCMSProps, getMetaCMSProps, initCMS } from 'utils/cms';
import { IS_NNORMAL } from 'utils/constants/system';
import ProductsRow from 'components/ProductsRow';
import Product from 'components/product';
import MembersOnlyModal from 'components/productPurchaseCol/membersOnlyModal';
import { getDomainValue } from 'utils/domain';

const LandingComponent = getDomainValue({
  camper: dynamic(() => import('@camper/react-web-components').then((res) => res.PAGES.LandingComponent)),
  camperlab: dynamic(() => import('@camper/react-web-components').then((res) => res.PAGES.LandingComponent)),
  nnormal: dynamic(() => import('@nnormal/react-web-components').then((res) => res.PAGES.DynamicLanding)),
});

const LandingSlots = {
  products: ProductsRow,
  product: Product,
};

const baseUrl = process.env.INTEGRA_DOMAIN;

export default function Content({ defaultProps, contentProps, metaTags, urlCanonical, isCamperLab, isPrivate }) {
  const extraTags = [<link key="fontscss" rel="StyleSheet" href="/assets-new/fonts/fonts.css" type="text/css" />];

  const [usrHashValue, setUsrHashValue] = useState(getCookie(COOKIES.SELLIGENT_USRHASH));
  const [memberEmail, setMemberEmail] = useState(getCookie(COOKIES.MEMBER_EMAIL));
  const [showMembersOnlyModal, setShowMembersOnlyModal] = useState(isPrivate && usrHashValue === null && memberEmail === null);

  useEffect(() => {
    try {
      const hash = window && window.location.hash ? window.location.hash : null;
      if (hash) {
        document.querySelector(hash);
      }
    } catch (error) {
      window.history.pushState('', document.title, window.location.pathname + window.location.search);
      window.location.reload();
    }

    if (typeof window !== 'undefined') {
      let intervalID = null;
      const cookieWatcher = () => {
        const usrhash = getCookie(COOKIES.SELLIGENT_USRHASH);
        const member_email = getCookie(COOKIES.MEMBER_EMAIL);

        if (usrhash !== usrHashValue) {
          setUsrHashValue(usrhash);
          clearInterval(intervalID);
        }

        if (member_email !== memberEmail) {
          setMemberEmail(member_email);
        }
      };

      intervalID = setInterval(cookieWatcher, 1000);
    }
  }, []);

  useEffect(() => {
    const cta = document.getElementById('ctaPopUp');

    if (usrHashValue !== null) {
      setTimeout(() => {
        if (cta !== null) {
          cta.style.display = 'none';
          cta.style.visibility = 'escondido';
          cta.style.color = '#07b323';
          cta.style.background = 'unset';
          cta.style.backgroundSize = 'unset';
          cta.style.animation = 'unset';
          cta.style.textDecoration = 'unset';
          cta.style.pointerEvents = 'none';
        }
      }, 1000);
    } else {
      setTimeout(() => {
        if (cta !== null) {
          cta.style.visibility = 'visible';
          cta.style.background = 'unset';
          cta.style.backgroundSize = 'unset';
          cta.style.animation = 'unset';
        }
      }, 1000);
    }
  }, [usrHashValue]);

  useEffect(() => {
    if (usrHashValue !== null || memberEmail !== null) {
      setShowMembersOnlyModal(isPrivate && usrHashValue === null && memberEmail === null);
    }
  }, [usrHashValue, memberEmail]);

  const content =
    isPrivate ?
      <>
        {(usrHashValue === null || memberEmail === null) && (
          <MembersOnlyModal nlOrigin="Landing_Kilian" loading={false} membersOnlyVisible={showMembersOnlyModal} type="private" />
        )}
        <LandingComponent slots={LandingSlots} {...contentProps} />
      </>
    : <LandingComponent slots={LandingSlots} {...contentProps} />;

  return (
    <Layout
      {...defaultProps}
      pageName={'content'}
      gtmProps={{ ...contentProps, url: urlCanonical }}
      extratags={[...extraTags, <link key="canonical" rel="canonical" href={urlCanonical} />]}
      metatags={metaTags}
      breadcrumbs={false}
      translateSlug={IS_NNORMAL}
      scrollArrow={true}
      camperlab={isCamperLab}
    >
      <div>{content}</div>
    </Layout>
  );
}

Content.i18nPage = 'Content';

Content.propTypes = {
  locale: string,
  defaultLocale: string,
  footerProps: any,
  tnmProps: any,
  contentProps: any,
  isCamperLab: bool,
  ribbon: any,
  metaTags: object,
  urlCanonical: string,
  isPrivate: bool,
};

// rutas que camper quiere que sean de content pero sin la apariencia de que lo sean
const customRoutes = ['together', 'sales', 'roku', 'roku/men', 'roku/women'];

export async function getServerSideProps(context) {
  const {
    params: { slug },
    locale,
    query,
  } = context;

  const contentType = query['filter.contentType'] ? query['filter.contentType'] : 'content';

  const metaSlug = customRoutes.includes(contentType) ? `/${slug.join('/')}` : `/content/${slug.join('/')}`;

  const landingSlug = customRoutes.includes(contentType) ? `/${slug.join('/')}` : `/${contentType}/${slug.join('/')}`;
  const [metaTags, landingProps] = await Promise.all([getMetaCMSProps({ locale, slug: metaSlug }), getLandingCMSProps({ locale, slug: landingSlug })]);

  if (landingProps.redirect !== null) {
    return {
      redirect: landingProps.redirect,
    };
  }

  if (landingProps.contentProps === null) {
    return {
      notFound: true,
    };
  }

  const isCamperLab = landingProps.contentProps?.isCamperLab === true;
  const urlCanonical = customRoutes.includes(contentType) ? `${baseUrl}/${locale}/${slug.join('/')}` : `${baseUrl}/${locale}/${contentType}/${slug.join('/')}`;
  const isPrivate = landingProps?.contentProps?.isPrivate === true;

  const { defaultProps } = await initCMS({ context, locale, camperlab: isCamperLab });
  return {
    props: {
      defaultProps,
      rel_parents: [],
      locale,
      defaultLocale: locale,
      contentProps: landingProps.contentProps,
      metaTags: metaTags ? { ...metaTags } : null,
      urlCanonical,
      isCamperLab,
      isPrivate,
    },
  };
}
