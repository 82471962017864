
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/content/[[...slug]]",
      function () {
        return require("private-next-pages/content/[[...slug]]/index.js");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/content/[[...slug]]"])
      });
    }
  